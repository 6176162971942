import { render, staticRenderFns } from "./device-order.vue?vue&type=template&id=cc4634e2&scoped=true"
import script from "./device-order.vue?vue&type=script&lang=ts"
export * from "./device-order.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc4634e2",
  null
  
)

export default component.exports